<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column" v-if="form.grade != 98">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" size="small">
			  <el-divider content-position="left">基本信息</el-divider>
			  <el-row>
				<el-col :span="12">
					<el-form-item label="单位名称" prop="name">
					<el-input v-model="form.name" placeholder="请输入单位名称" type="text" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="12">
				   <el-form-item label="单位性质" prop="dw_xz">
					 <el-input v-model="form.dw_xz" placeholder="请输入单位性质" type="text" clearable />
				   </el-form-item>
				</el-col>
			  </el-row>
			  <el-row>
				  <el-col :span="12">
				    <el-form-item label="会员类型" prop="grade">
				  	 <el-select v-model="form.grade" placeholder="请选择会员类型" style="width: 100%" size="medium">
						  <el-option label="未选" :value="0"></el-option>
				  	      <el-option
				  	        v-for="item in grades"
				  	        :key="item.id"
				  	        :label="item | tree"
				  	        :value="item.id"
				  	      />
				  	    </el-select>
				  	 </el-form-item>
				    </el-form-item>
				  </el-col>
				<el-col :span="12">
				  <el-form-item label="其他类型" prop="grade">
					 <el-select v-model="form.grade2" placeholder="请选择会员类型" style="width: 100%" size="medium">
						  <el-option label="未选" :value="0"></el-option>
						  <el-option
							v-for="item in grades"
							:key="item.id"
							:label="item | tree"
							:value="item.id"
						  />
					</el-select>
					<small class="text-danger">不属于多类型可不选</small>
					</el-form-item>
				</el-form-item>
			  </el-col>
			  </el-row>
			  <el-row>
				<el-col :span="12">
				  <el-form-item label="密码" prop="password">
					<el-input v-model="form.password" placeholder="请输入密码" type="text" clearable />
					<small class="text-danger" v-if="form.id">为空，表示不修改密码</small>
				  </el-form-item>
				</el-col>
				<el-col :span="12">
				  <el-form-item label="注册资金" prop="reg_money">
					<el-input v-model="form.reg_money" placeholder="请输入注册资金(万)" type="text" clearable />
				  </el-form-item>
				</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="法人姓名" prop="legal_person">
						<el-input v-model="form.legal_person" placeholder="请输入法人姓名" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="法人电话" prop="l_phone">
						 <el-input v-model="form.l_phone" placeholder="请输入法人电话" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="法人手机" prop="l_mobile">
						<el-input v-model="form.l_mobile" placeholder="请输入法人手机" type="number" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					  <el-form-item label="法人职务" prop="l_duties">
						<el-input v-model="form.l_duties" placeholder="请输入法人职务" type="text" clearable />
					  </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					   <el-form-item label="法人邮箱" prop="l_email">
						 <el-input v-model="form.l_email" placeholder="请输入法人邮箱" type="text" clearable />
					   </el-form-item>
					</el-col>
					
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="联系人" prop="work_person">
						<el-input v-model="form.work_person" placeholder="请输入联系人" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="联系人电话" prop="work_phone">
						 <el-input v-model="form.work_phone" placeholder="请输入联系人电话" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="联系人手机" prop="work_mobile">
						<el-input v-model="form.work_mobile" placeholder="请输入联系人手机" type="number" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="联系人职务" prop="work_duties">
						 <el-input v-model="form.work_duties" placeholder="请输入联系人职务" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					   <el-form-item label="联系人邮箱" prop="work_email">
						 <el-input v-model="form.work_email" placeholder="请输入联系人邮箱" type="text" clearable />
					   </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="从业人数" prop="people_num">
						 <el-input v-model="form.people_num" placeholder="请输入从业人数(人)" type="number" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="通信地址" prop="address">
						<el-input v-model="form.address" placeholder="请输入通信地址" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="邮政编码" prop="zip_code">
						 <el-input v-model="form.zip_code" placeholder="请输入邮政编码" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
				<el-col :span="12">
				  <el-form-item label="产值或营业额" prop="cz">
					<el-input v-model="form.cz" placeholder="请输入产值营业额(万)" type="text" clearable />
				  </el-form-item>
				</el-col>
				<el-col :span="12">
				   <el-form-item label="纳税额" prop="nse">
					 <el-input v-model="form.nse" placeholder="请输入纳税额(万)" type="number" clearable />
				   </el-form-item>
				</el-col>
			  </el-row>
			  <el-divider content-position="left">等级资质</el-divider>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="承装等级" prop="cz_grade">
						<el-input v-model="form.cz_grade" placeholder="请输入承装等级" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="承修等级" prop="cx_grade">
						 <el-input v-model="form.cx_grade" placeholder="请输入承修等级" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="承试等级" prop="cs_grade">
						<el-input v-model="form.cs_grade" placeholder="请输入承试等级" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="新能源" prop="xny_grade">
						 <el-input v-model="form.xny_grade" placeholder="请输入新能源等级" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="发电" prop="fd_grade">
						<el-input v-model="form.fd_grade" placeholder="请输入发电等级" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="供电" prop="gd_grade">
						 <el-input v-model="form.gd_grade" placeholder="请输入供电等级" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="售电" prop="sd_grade">
						<el-input v-model="form.sd_grade" placeholder="请输入售电等级" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="设计" prop="sj_grade">
						 <el-input v-model="form.sj_grade" placeholder="请输入设计等级" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
				<el-col :span="12">
				  <el-form-item label="监理" prop="jl_grade">
					<el-input v-model="form.jl_grade" placeholder="请输入监理等级" type="text" clearable />
				  </el-form-item>
				</el-col>
                 <el-col :span="12">
                   <el-form-item label="能源局资质" prop="xny_aptitude">
                 	<el-input v-model="form.xny_aptitude" placeholder="请输入能源局资质" type="text" clearable />
                   </el-form-item>
                 </el-col>
			  </el-row>
			  <el-row>
				<el-col :span="12">
				   <el-form-item label="其他电力相关资质" prop="other_aptitude">
					 <el-input v-model="form.other_aptitude" placeholder="请输入其他电力相关资质" type="text" clearable />
				   </el-form-item>
				</el-col>
			  </el-row>
			  <el-divider content-position="left">历届职务</el-divider>
			  <el-row>
				<el-col :span="12">
				  <el-form-item label="第一届所任职务" prop="one_duties">
					<el-input v-model="form.one_duties" placeholder="请输入第一届所任职务" type="text" clearable />
				  </el-form-item>
				</el-col>
				<el-col :span="12">
				   <el-form-item label="第二届拟任职务" prop="two_duties">
					 <el-input v-model="form.two_duties" placeholder="请输入第二届拟任职务" type="text" clearable />
				   </el-form-item>
				</el-col>
			  </el-row>
			  <el-row>
				<el-col :span="12">
				  <el-form-item label="第三届拟任职务" prop="three_duties">
					<el-input v-model="form.three_duties" placeholder="请输入第三届拟任职务" type="text" clearable />
				  </el-form-item>
				</el-col>
			  </el-row>
			   <el-divider content-position="left">其他信息</el-divider>
			   <el-row>
				   <el-col :span="12">
					  <el-form-item label="许可证编号" prop="cz_code">
						<el-input v-model="form.cz_code" placeholder="请输入许可证编号" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="许可证有效期" prop="xkz_time">
						 <el-date-picker
							 v-model="form.xkz_time"
							 value-format="yyyy-MM-dd"
							 type="daterange"
							 range-separator="至"
							 start-placeholder="开始日期"
							 end-placeholder="结束日期">
						 </el-date-picker>
					   </el-form-item>
					</el-col>
			   </el-row>
			   <el-row>
                   <el-col :span="12">
                      <el-form-item label="统一社会信用代码" prop="xy_code">
                   	 <el-input v-model="form.xy_code" placeholder="请输入统一社会信用代码" type="text" clearable />
                      </el-form-item>
                   </el-col>
				   <el-col :span="12">
				      <el-form-item label="会员到期时间" prop="desc">
				   		 <el-date-picker
				   			 v-model="form.end_time"
				   			 value-format="yyyy-MM-dd"
				   			 type="date"
				   			 placeholder="选择会员到期时间">
				   		 </el-date-picker>
				   	 </el-form-item>
				      </el-form-item>
				   </el-col>
			   </el-row>
			  <el-row>
				  <el-col :span="12">
				     <el-form-item label="简介" prop="desc">
				  	 <el-input placeholder="请输入简介" size="medium" v-model="form.desc" type="textarea" :rows="3"></el-input>
				     </el-form-item>
				  </el-col>
				  <el-col :span="12">
				     <el-form-item label="区域">
				  	   <el-cascader
				  		 class="w-100"
				  		 size="large"
				  		 :options="options"
				  		 v-model="areaOptions"
				  		 @change="areaChange">
				  	   </el-cascader>
				     </el-form-item>
				  </el-col>
			  </el-row>
			  <el-row>
			  <el-col :span="12">
				<el-form-item label="头像" prop="avatar">
				  <el-upload
					class="upload-border"
					action="/manage/images/upload"
					:show-file-list="false"
					:on-success="uploadSuccess"
					:before-upload="beforeUpload">
					<img v-if="form.avatar != ''" :src="form.avatar" class="w-100 h-100">
					<i v-else class="el-icon-plus uploader-icon"></i>
				  </el-upload>
				</el-form-item>
			  </el-col>
			  <el-col :span="12">
			  </el-col>
			  </el-row>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		
		<div class="w-100 h-auto flex flex-column" v-if="form.grade == 98">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" size="small">
			  <el-row>
				<el-col :span="12">
					<el-form-item label="单位名称" prop="name">
					<el-input v-model="form.name" placeholder="请输入单位名称" type="text" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					 <el-form-item label="密码" prop="password">
						<el-input v-model="form.password" placeholder="请输入密码" type="text" clearable />
						<small class="text-danger" v-if="form.id">为空，表示不修改密码</small>
					 </el-form-item>
				</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="姓名" prop="legal_person">
						<el-input v-model="form.legal_person" placeholder="请输入姓名" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="电话" prop="l_phone">
						 <el-input v-model="form.l_phone" placeholder="请输入电话" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="手机">
						<el-input v-model="form.l_mobile" placeholder="请输入手机" type="number" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="传真" prop="l_fax">
						 <el-input v-model="form.l_fax" placeholder="请输入传真" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="联系人">
						<el-input v-model="form.work_person" placeholder="请输入联系人" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="联系人手机">
						 <el-input v-model="form.work_mobile" placeholder="请输入联系人手机" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			  <el-row>
					<el-col :span="12">
					  <el-form-item label="通信地址">
						<el-input v-model="form.address" placeholder="请输入通信地址" type="text" clearable />
					  </el-form-item>
					</el-col>
					<el-col :span="12">
					   <el-form-item label="邮政编码">
						 <el-input v-model="form.zip_code" placeholder="请输入邮政编码" type="text" clearable />
					   </el-form-item>
					</el-col>
			  </el-row>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'user',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				name:'',
				grade:[],
				password:'',
				dw_xz:'',
				legal_person:'',
				l_phone:'',
				l_mobile:'',
				l_fax:'',
				l_duties:'',
				l_email:'',
				work_person:'',
				work_phone:'',
				work_mobile:'',
				work_duties:'',
				work_fax:'',
				work_email:'',
				address:'',
				zip_code:'',
				reg_money:'',
				people_num:'',
				cz_code:'',
				cz_grade:'',
				cx_grade:'',
				cs_grade:'',
				xkz_time:[],
				other_aptitude:'',
				desc:'',
				area_id:'',
				sort:100,
				status:1,
				end_time:'',
				avatar:'',
				role:0,
			},
			rules:{
				name: [
				  { required: true, message: '请输入单位名称', trigger: 'blur' }
				],
				// legal_person: [
				//   { required: true, message: '请输入单位法人', trigger: 'blur' }
				// ],
				// l_mobile: [
				//   { required: true, message: '请输入单位手机', trigger: 'blur' }
				// ],
				// work_person: [
				//   { required: true, message: '请输入联系人', trigger: 'blur' }
				// ],
				// work_mobile: [
				//   { required: true, message: '请输入联系人手机', trigger: 'blur' }
				// ],
				// address: [
				//   { required: true, message: '请输入单位地址', trigger: 'blur' }
				// ],
			},
			grades:[],
			province:"",
		    city:"",
		    country:"",
			options: regionData,
			areaOptions: [],
			roles:[],
		}
	},
	mounted() {
		if(this.$route.query.user){
			let item =  this.$route.query.user
			 this.form = item
			 this.form.password = ''
			 //区域
			 this.$nextTick(()=>{
				 if(item.area_ids && item.area_ids.length > 0){
					 this.province = CodeToText[item.area_ids[0]]
					 this.city = CodeToText[item.area_ids[1]]
					 this.country = CodeToText[item.area_ids[2]]
					 this.areaOptions = [
						 TextToCode[this.province].code,
						 TextToCode[this.province][this.city].code,
						 TextToCode[this.province][this.city][this.country].code
					 ]
				 }
			 })
			
			 // if(item.grade.length>0){
				//  let arr = []
				//  item.grade.forEach(v=>{
				// 	 arr.push(JSON.parse(v))
				//  })
			 // 	 this.form.grade = arr
			 // }
		}
		if(this.$route.query.grade){
			this.form.grade = this.$route.query.grade
		}
		
		this.getGrades()
		this.getUserRoles()
	},
	methods:{
		reset() {
		  this.form = {
			  name:'',
			  grade:'',
			  grade2:'',
			  password:'',
			  dw_xz:'',
			  legal_person:'',
			  l_phone:'',
			  l_mobile:'',
			  l_fax:'',
			  l_duties:'',
			  l_email:'',
			  work_person:'',
			  work_phone:'',
			  work_mobile:'',
			  work_duties:'',
			  work_fax:'',
			  work_email:'',
			  address:'',
			  zip_code:'',
			  reg_money:'',
			  people_num:'',
			  cz_code:'',
			  cz_grade:'',
			  cx_grade:'',
			  cs_grade:'',
			  xkz_time:'',
			  other_aptitude:'',
			  desc:'',
			  area_id:'',
			  sort:100,
			  status:1,
			  end_time:'',
			  avatar:'',
			  role:0,
		  }
		  this.resetForm('form')
		},
		uploadSuccess(e){
		   if(e.status){
			 this.form.avatar = e.data.url
		   }
		},
		beforeUpload(){},
		areaChange() {
			this.province= CodeToText[this.areaOptions[0]]
			this.city= CodeToText[this.areaOptions[1]]
			this.country= CodeToText[this.areaOptions[2]]
			this.form.area_id= this.areaOptions[2]
		},
		getGrades(){
			this.axios.get('/manage/user_grade/level').then(res=>{
			  if(res.status){
				let grades = res.data
				this.grades = grades
			  }
			})
		},
		getUserRoles(){
			this.axios.get('/manage/user_role/all').then(res=>{
			  if(res.status){
				this.roles = res.data
			  }
			})
		},
		chooseImage(){
			this.app.chooseImage((res)=>{
			  this.form.cover = res[0].id
			  this.form.url = res[0].url
			})
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
	filters: {
	    tree(item) {
	        if (item.level == 0) {
	          return item.name
	        }
	        let str = ''
	        for (let i = 0; i < item.level; i++) {
	          str += i == 0 ? '|--' : '--'
	        }
	        return str + ' ' +item.name;
	    }
	},
}
</script>

<style>
</style>